@import "./variables";

.button {
  background: rgba(218, 7, 162, 1);
  background: -moz-linear-gradient(
    45deg,
    rgba(218, 7, 162, 1) 0%,
    rgba(93, 68, 165, 1) 47%,
    rgba(53, 176, 230, 1) 100%
  );
  background: -webkit-gradient(
    left bottom,
    right top,
    color-stop(0%, rgba(218, 7, 162, 1)),
    color-stop(47%, rgba(93, 68, 165, 1)),
    color-stop(100%, rgba(53, 176, 230, 1))
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(218, 7, 162, 1) 0%,
    rgba(93, 68, 165, 1) 47%,
    rgba(53, 176, 230, 1) 100%
  );
  background: -o-linear-gradient(
    45deg,
    rgba(218, 7, 162, 1) 0%,
    rgba(93, 68, 165, 1) 47%,
    rgba(53, 176, 230, 1) 100%
  );
  background: -ms-linear-gradient(
    45deg,
    rgba(218, 7, 162, 1) 0%,
    rgba(93, 68, 165, 1) 47%,
    rgba(53, 176, 230, 1) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(218, 7, 162, 1) 0%,
    rgba(93, 68, 165, 1) 47%,
    rgba(53, 176, 230, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#da07a2', endColorstr='#35b0e6', GradientType=1 );
  height: 50px;
  border-radius: 30px;
  padding: 0 25px;
  color: white;
  font-size: 13px;
  letter-spacing: 0.25px;
  min-width: 130px;
  font-weight: bold;
  border: none;
  box-shadow: 0px 0px 12px 4px rgba($color: $glowing-purple, $alpha: 1);
  transition: all 0.36s ease;

  &:active {
    background: rgba(53, 176, 230, 1);
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      rgba(53, 176, 230, 1) 0%,
      rgba(93, 68, 165, 1) 91%,
      rgba(218, 7, 162, 1) 100%
    );
    background: -webkit-gradient(
      radial,
      center center,
      0px,
      center center,
      100%,
      color-stop(0%, rgba(53, 176, 230, 1)),
      color-stop(91%, rgba(93, 68, 165, 1)),
      color-stop(100%, rgba(218, 7, 162, 1))
    );
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      rgba(53, 176, 230, 1) 0%,
      rgba(93, 68, 165, 1) 91%,
      rgba(218, 7, 162, 1) 100%
    );
    background: -o-radial-gradient(
      center,
      ellipse cover,
      rgba(53, 176, 230, 1) 0%,
      rgba(93, 68, 165, 1) 91%,
      rgba(218, 7, 162, 1) 100%
    );
    background: -ms-radial-gradient(
      center,
      ellipse cover,
      rgba(53, 176, 230, 1) 0%,
      rgba(93, 68, 165, 1) 91%,
      rgba(218, 7, 162, 1) 100%
    );
    background: radial-gradient(
      ellipse at center,
      rgba(53, 176, 230, 1) 0%,
      rgba(93, 68, 165, 1) 91%,
      rgba(218, 7, 162, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35b0e6', endColorstr='#da07a2', GradientType=1 );
  }

  &:hover {
    box-shadow: 0px 5px 12px 4px rgba($color: $glowing-purple, $alpha: 1);
    transform: translateY(-5px);
  }

  &.circle {
    width: 50px;
    max-width: 50px;
    min-width: auto;
    padding: 0;
  }

  &.miniBtn{
    height: 30px;
    padding: 0 12px;
    min-width: 80px;
  }

  &.small {
    min-width: 80px;
    height: 35px;
  }
}
