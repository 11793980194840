@import "./mixins";

.collapsing {
  transition: height 0.5s ease !important;
}

.progress {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: rgba($color: white, $alpha: .1);
  .progress-bar {
    @include linear-gradient(#df05ff, #0968b8);
  }
}
