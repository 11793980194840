@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }  
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }  
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}

@mixin box-shadow($level) {
  @if $level == level-1 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.16);
  }
  @if $level == level-2 {
    box-shadow: 0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.16);
  }
  @if $level == level-2-invert {
    box-shadow: 0 -3px 6px rgba(0,0,0,0.12), 0 -2px 6px rgba(0,0,0,0.16);
  }
  @if $level == level-3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.12), 0 6px 6px rgba(0,0,0,0.16);
  }
  @if $level == level-4 {
    box-shadow: 0 14px 28px rgba(0,0,0,0.12), 0 10px 10px rgba(0,0,0,0.16);
  }
  @if $level == level-5 {
    box-shadow: 0 19px 38px rgba(0,0,0,0.09), 0 15px 12px rgba(0,0,0,0.16);
  }
}

@mixin media-breakpoint-range($device) {
  @if $device == small-mobile {
    @media (max-width: 370px) { @content; }
  }
  @if $device == mobile {
    @media (max-width: 599px) { @content; }
  }
  @if $device == mobile-landscape {
    @media (orientation: landscape) and (max-width: 670px)   { @content; }
  }
  @else if $device == tab-up {
    @media (min-width: 600px) { @content; }
  }
  @else if $device == tab-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @else if $device == desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @else if $device == large-display-up {
    @media (min-width: 1800px) { @content; }
  }
}

@mixin linear-gradient ($start, $end) {
  background: $start;
  background: -moz-linear-gradient(left, $start 0%, $end 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, $start), color-stop(100%, $end));
  background: -webkit-linear-gradient(left, $start 0%, $end 100%);
  background: -o-linear-gradient(left, $start 0%, $end 100%);
  background: -ms-linear-gradient(left, $start 0%, $end 100%);
  background: linear-gradient(to right, $start 0%, $end 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start', endColorstr='$end', GradientType=1 );
}
body:not(.modal-open){
  padding-right: 0px !important;
}
.fista-wrp{
  display: flex;
  justify-content: center;
  padding: 100px 0px;
}
.modal-content{
  border-radius: 32px;
  background-color: #161654;
  box-shadow: 0px 0px 12px 4px #ff00ff99;
  color: #fff;
}
.pool-box{
  width: 95%;
  padding: 30px;
    border-radius: 32px;
    background-color: #161654;
    box-shadow: 0px 0px 12px 4px #ff00ff99;
}
.mfista{
  width: 200px;
}
.fista-p{
  font-size: 54px;
}
 
.roiarea table {
  width: 90%;
  margin: 30px auto;
}
.roiarea td {
  text-align: left;
}
 
.paragraph{
  font-size: 18px;
  margin: 0px;
  margin-top: -5px;
}
.fista-comtent{
  align-self: center;
  margin-right: 40px;
}
.naut-img{
  padding: 20px;
  border-radius: 20px;
  background-color: #0f0230;
}
.Mfista3 {
  width: 60px;
}
.roiarea > table tr {
  height: 10px;
}
.roiicon {
  width: 13px;
  cursor: pointer;
  margin-top: -5px;
}
.close{
  color: #fff!important;
}
.roiarea p {
  width: 90%;
  margin: 10px auto;
}
.naut-wrp{
  display: flex;
}
.naut-content{
  margin-left: 20px;
  align-self: center;
}
.naut-content h3{
  font-size: 26px;
  text-transform: capitalize;
}
.core-r-content.padding {
  width: 50%;
  float: right;
}
.stokes{
  background-color: #00aeef;
  height: 3px;
  border-radius: 32px;
  margin-top: 30px;
}
.wrp-arean{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.earn-number p {
  font-size: 40px;
  margin: 0px;
}
.earn-text{
  align-self: center;
}
.earn-text p {
  margin: 0px;
  font-size: 22px;
  color: #2f89c9;
  line-height: 23px;
  text-align: right;
}
.core-wrp{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.right {
  width: 15px;
  margin-bottom: 14px;
}
.core-l-content p {
  margin: 0px;
  font-size: 22px;
  color: #2f89c9;
}
.right-img{
  text-align: right;
}
.core-l-content a{
  font-size: 22px;
  color: #2f89c9;
  border-bottom: 1px solid #2f89c9;
  outline: none;
  
}
.inactive {
  background: linear-gradient(45deg, #07dabc 0%, #449ca5 47%, #35e6cd 100%) !important;
  box-shadow: 0px 0px 12px 4px #00f3ff !important;
}
.error {
  color: red;
  font-style: italic;
}
.core-r-content.centeredbtn button {
  width: auto;
  margin-top: 30px;
}
.depbtn {
  width: 80px !important;
  min-width: auto !important;
  font-size: 32px !important;
}
.core-r-content > button , .core-l-content > button{
  background: #da07a2;
    background: -webkit-gradient(left bottom, right top, color-stop(0%, #da07a2), color-stop(47%, #5d44a5), color-stop(100%, #35b0e6));
    background: linear-gradient(
45deg
, #da07a2 0%, #5d44a5 47%, #35b0e6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#da07a2", endColorstr="#35b0e6", GradientType=1 );
    height: 50px;
    border-radius: 30px;
    padding: 0 25px;
    color: white;
    font-size: 14px;
    letter-spacing: 0.25px;
    min-width: 130px;
    font-weight: bold;
    border: none;
    box-shadow: 0px 0px 12px 4px magenta;
    transition: all 0.36s ease;

}
.core-r-content > button:active , .core-l-content > button:active{
  background: radial-gradient(ellipse at center, #35b0e6 0%, #5d44a5 91%, #da07a2 100%);
}
.core-r-content > button:hover , .core-l-content > button:hover{
  box-shadow: 0px 5px 12px 4px fuchsia;
  transform: translateY(-5px);
}

.depositButton:hover{
  box-shadow: 0px 5px 12px 4px fuchsia;
  transform: translateY(-5px);
}

.depositButton:active{
  background: radial-gradient(ellipse at center, #35b0e6 0%, #5d44a5 91%, #da07a2 100%);
}

.pool-box:hover {
  box-shadow: 0px 0px 18px 14px #f0f9;
}
.arp-wrp{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.arp-c-left p{
  margin: 0px;
  font-size: 20px;
  color: #2f89c9;
}
.arp-c-left span{
  font-size: 20px;
  color: #2f89c9;

}
.arp-c-right > p{
  margin: 0px;
  font-size: 20px;
  color: #fff;
  text-align: right;
  float: right;
  clear: both;
}

.max {
  border: 1px dashed #fff;
  padding: 3px 6px;
  cursor: pointer;
}
.max:hover {
  background: #594fac;
  color: #fff;
}

.animated-bg{
  background-image: url('../images/animated-banner.gif');
  background-size: cover;
}

 
 html,body {
  margin: 0;
  padding: 0;
  font-family:'Lato', sans-serif;
}
.loader {
  width: 100px;
  height: 80px;
  position: absolute;
  top: 0; right: 0; left: 0; bottom: 0;
  margin: auto;
}
.loader .image {
  width: 100px;
  height: 160px;
  font-size: 40px;
  text-align: center;
  transform-origin: bottom center;
  animation: 3s rotate infinite;
  opacity: 0;
}
.loader span {
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
}

@keyframes rotate{
  0% {
    transform: rotate(90deg);
  }
  10% {
    opacity: 0;
  }
  35% {
    transform: rotate(0deg);
    opacity: 1;
  }
  65% {
    transform: rotate(0deg);
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    transform: rotate(-90deg);
  }
}


// project box css

.project-box{
  width: 95%;
    padding: 30px;
    border-radius: 32px;
    background-color: #161654;
    box-shadow: 0px 0px 12px 4px #ff00ff99;
}
.project-box > h4{
  font-weight: 800;
  font-size: 48px;
  margin-top: 5px;
  margin-bottom: 0px;
}
.project-box > p{
  margin: 0px;
  font-size: 18px;
  color: #fff;
  margin-top: -8px;
}

.project-box > a:hover{
  box-shadow: 0px 5px 12px 4px fuchsia;
  transform: translateY(-5px);
  text-decoration: none;
}

.project-box > a:active{
  background: radial-gradient(ellipse at center, #35b0e6 0%, #5d44a5 91%, #da07a2 100%);
}


.project-box > a{
  background: #da07a2;
    background: -webkit-gradient(left bottom, right top, color-stop(0%, #da07a2), color-stop(47%, #5d44a5), color-stop(100%, #35b0e6));
    background: linear-gradient(
45deg
, #da07a2 0%, #5d44a5 47%, #35b0e6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#da07a2", endColorstr="#35b0e6", GradientType=1 );
    height: 50px;
    border-radius: 30px;
    padding: 15px 35px;
    color: white;
    font-size: 15px;
    letter-spacing: 0.25px;
    width: auto;
    font-weight: bold;
    border: none;
    box-shadow: 0px 0px 12px 4px magenta;
    transition: all 0.36s ease;
    margin-top: 25px;
    display: inline-block;
 
text-align: center;
}
.apr-wrp{
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}
.apr-content > p{
  margin: 0px;
  font-weight: bold;
  font-size: 20px;
  color: #fff;
}
.apr-content2 > p{
  margin: 0px;
  font-weight: bold;
  font-size: 20px;
  color: #fff;
}
.stakes-wrp{
  display: flex;
  justify-content: space-between;
}
.stakes-content > p{
  margin: 0px;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
}
.stakes-content2 > p{
  margin: 0px;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
}
.icon-img{
  width: 30px;
}
.customer{
  width: 30px;
  margin-right: 10px;
}

.flower-img img {
  width: 65px;
}
.Mfista2 {
display: none;
}

.fista-img {
  width: 400px;
}

.arp-c-right > span{
  font-size: 20px;
  color: #fff;
  text-align: right;
  float: right;
  clear: both;

}
.mrt-fista{
  margin-top: 60px;
}
.wrp-inactive{
  display: flex;
}
.child-inactive1 button{
  background-color: #15124d;
  height: 60px;
  color: white;
  border: none;
  transition: all 0.36s ease;
  border-radius: 50px;
  width: 200px;
  font-size: 18px;
}
.wrp-inactive {
  display: flex;
  margin-bottom: 70px;
}
.navigation_active__12fru{
  box-shadow: 0px 0px 20px 4px magenta;
    background: #c30c9f;
    background: -webkit-gradient(left bottom, right top, color-stop(0%, #c30c9f), color-stop(57%, #593da1), color-stop(100%, #2f7fc2));
    background: linear-gradient(
45deg
, #c30c9f 0%, #593da1 57%, #2f7fc2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#c30c9f", endColorstr="#2f7fc2", GradientType=1 );
    z-index: 1;
}
.b-tab {

  display: none;
}

.b-tab.active {
  display: block;
}

.b-nav-tab {
  display: inline-block;
}

.b-nav-tab.active {
  box-shadow: 0px 0px 20px 4px magenta;
    background: #c30c9f;
    background: -webkit-gradient(left bottom, right top, color-stop(0%, #c30c9f), color-stop(57%, #593da1), color-stop(100%, #2f7fc2));
    background: linear-gradient(
45deg
, #c30c9f 0%, #593da1 57%, #2f7fc2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#c30c9f", endColorstr="#2f7fc2", GradientType=1 );
    z-index: 1;
}
.mart-f{
  margin-left: 15px;
}
.moveRight {
  float: right;
  width: 100%;
}
.moveRight > span {
  text-align: right;
  float: right;
}
.depositCancelButton{
  background: linear-gradient(45deg, #da0707 0%, #a5445c 47%, #e63561 100%);
  height: 40px;
  border-radius: 30px;
  padding: 0 25px;
  color: white;
  font-size: 15px;
  letter-spacing: 0.25px;
  min-width: 130px;
  font-weight: bold;
  border: none!important;
  box-shadow: 0px 0px 12px 4px red;
  transition: all 0.36s ease;
}
.depositButton{
  background: linear-gradient(45deg, #da07a2 0%, #5d44a5 47%, #35b0e6 100%);
  height: 40px;
  border-radius: 30px;
  padding: 0 25px;
  color: white;
  font-size: 15px;
  letter-spacing: 0.25px;
  min-width: 130px;
  font-weight: bold;
  border: none!important;
  box-shadow: 0px 0px 12px 4px magenta;
  transition: all 0.36s ease;
}

 
.exchange-box{
  width: 95%;
    padding: 30px;
    border-radius: 32px;
    background-color: #161654;
    box-shadow: 0px 0px 12px 4px #ff00ff99;
}
.exchange-box > h3{
  margin: 0px;
  font-size: 24px;
}
.exchange-box > p{
  margin: 0px;
  font-size: 15px;
  margin-top: 8px;
}
.stokes-new{
  background-color: #00aeef;
    height: 1px;
    border-radius: 32px;
    margin-top: 30px;
}
.flex-box-wrp{
  display: flex;
  justify-content: space-between;

}
.from-box-wrp{
    background-color: #0f0230;
    padding: 15px;
    border-radius: 12px;
    margin-top: 20px;
}
.from-box-c1 > input{
  display: inherit;
  background-color: transparent;
  border: none;
  font-size: 14px;
  color: #fff;
  width: auto;
}
.from-box-c1 > h4{
  margin: 0px;
  font-size: 14px;
  text-align: right;
}
.t-r{
  text-align: right! important;
}
.slip-wrp{
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.from-box-c1{
  margin: 0px;
  font-size: 14px;
  width: 53%;
}
.notice {
  border: 1px dashed #fff;
  padding: 10px;
}
.notice p {
  margin-bottom: 0px;
}
.slip-content1 > p{
  color: #2f89c9;
  font-size: 14px;
}
.slip-content2 > p{
  color: #fff;
  font-size: 14px;
}
.exchange-box > button{
  display: inline-block;
  background: #da07a2;
    background: -webkit-gradient(left bottom, right top, color-stop(0%, #da07a2), color-stop(47%, #5d44a5), color-stop(100%, #35b0e6));
    background: linear-gradient(
45deg
, #da07a2 0%, #5d44a5 47%, #35b0e6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#da07a2", endColorstr="#35b0e6", GradientType=1 );
    height: 50px;
    border-radius: 30px;
    padding: 0 25px;
    color: white;
    font-size: 15px;
    letter-spacing: 0.25px;
    width: 100%;
    font-weight: bold;
    border: none;
    box-shadow: 0px 0px 12px 4px magenta;
    transition: all 0.36s ease;
}

.no-outline{
  outline: none;
}

.modaltext{
  color: #fff;
  text-align: center;
  padding: 5%;
}

.sqrBx {
  float: right;
  box-shadow: 0px 0px 20px 4px magenta;
  background: linear-gradient(45deg, #c30c9f 0%, #593da1 57%, #2f7fc2 100%);
  border: none;
  color: #fff;
  display: flex;
  align-self: center;
  padding: 14px;
  margin-left: 25px;
}
.disconnect-box > .modal-content  {
  border-radius: 32px;
  background-color: #161654;
  box-shadow: 0 0 12px 4px rgba(255,0,255,.6);
  width: 530px;
}
.walletText {
  font-size: 20px;
}
.connect-box > .modal-content  {
  border-radius: 32px;
  background-color: #161654;
  box-shadow: 0 0 12px 4px rgba(255,0,255,.6);
  width: 350px;
}
.bottomtext a {
  color: #fff;
  text-decoration: none;
}
.bottomtext a:hover {
  text-decoration: none;
}
.bottomtext {
  text-align: center;
  margin-top: 35px;
}
.modal-header .close {
  color: #fff;
}
.connectbtn {
  background: linear-gradient(45deg,#da07a2,#5d44a5 47%,#35b0e6);
  box-shadow: 0 0 12px 4px #f0f;
  height: 40px;
  border-radius: 30px;
  padding: 10px 25px;
  color: #fff;
  font-size: 15px;
  letter-spacing: .25px;
  width: 100%;
  font-weight: 700;
  border: none !important;
  display: block;
  transition: all .36s ease;
  cursor: pointer;
}

.connectbtn:active {
  background: radial-gradient(ellipse at center,#35b0e6 0,#5d44a5 91%,#da07a2 100%);
      background-color: rgba(0, 0, 0, 0);
}
.connectbtn:hover {
  box-shadow: 0 5px 12px 4px #f0f;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

@media only screen and (max-width: 768px) {

  .exchange-box {
    width: 100%;
  }
}  
@media only screen and (max-width: 580px) {
  .fista-wrp {
    display: inherit;
    padding: 20px 0px;
}
.child-inactive1 button {
  height: 50px;
  width: 150px;
  font-size: 18px;
}
.wrp-inactive {
  margin-bottom: 25px;
}
.naut-img {
  padding: 15px;
}
.Mfista3 {
  width: 60px;
}
.naut-content h3 {
  font-size: 24px;
}
.earn-number p {
  font-size: 36px;
}
.earn-text p {
  font-size: 18px;
  line-height: 20px;
}
.core-l-content a{
  font-size: 20px;
}
.core-l-content p{
  font-size: 20px;
}
.arp-c-left p{
  font-size: 20px;
}
.arp-c-left span {
  font-size: 20px;
  
}
.arp-c-right > p{
  font-size: 20px;
}
.arp-c-right > span {
  font-size: 20px;
}
.Mfista2 {
  width: 100%;
  margin-top: 30px;
  display: block;
}
.fista-comtent {
  align-self: center;
  margin-right: 0px;
  text-align: center;
}
.pool-box{
  width: 100%;
  margin-top: 40px;
}
.mrt-fista {
  margin-top: 0px;
}

}

