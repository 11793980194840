@import "./mixins";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: $regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $bold;
  text-transform: uppercase;
}

section {
  padding-top: 40px;
  padding-bottom: 40px;
  @include media-breakpoint-range(tab-up) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

h2.section-title {
  font-family: $regular;
  letter-spacing: 7px;
  text-shadow: 0px 2px 12px rgba($color: $glowing-purple, $alpha: 1);
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 34px;
  line-height: 46px;

  @include media-breakpoint-range(tab-up) {
    font-size: 42px;
    line-height: 54px;
  }
}

h2.section-title-no-glow {
  font-family: $regular;
  letter-spacing: 7px;
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 34px;
  line-height: 46px;

  @include media-breakpoint-range(tab-up) {
    font-size: 42px;
    line-height: 54px;
  }
}

.highlight {
  background: rgba(218, 7, 162, 1);
  background: -moz-linear-gradient(
    left,
    rgba(218, 7, 162, 1) 0%,
    rgba(93, 68, 165, 1) 50%,
    rgba(53, 176, 230, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(218, 7, 162, 1)),
    color-stop(50%, rgba(93, 68, 165, 1)),
    color-stop(100%, rgba(53, 176, 230, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(218, 7, 162, 1) 0%,
    rgba(93, 68, 165, 1) 50%,
    rgba(53, 176, 230, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(218, 7, 162, 1) 0%,
    rgba(93, 68, 165, 1) 50%,
    rgba(53, 176, 230, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(218, 7, 162, 1) 0%,
    rgba(93, 68, 165, 1) 50%,
    rgba(53, 176, 230, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(218, 7, 162, 1) 0%,
    rgba(93, 68, 165, 1) 50%,
    rgba(53, 176, 230, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#da07a2', endColorstr='#35b0e6', GradientType=1 );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;

  &.inverse {
    background: rgba(53, 176, 230, 1);
    background: -moz-linear-gradient(
      left,
      rgba(53, 176, 230, 1) 0%,
      rgba(93, 68, 165, 1) 50%,
      rgba(218, 7, 162, 1) 100%
    );
    background: -webkit-gradient(
      left top,
      right top,
      color-stop(0%, rgba(53, 176, 230, 1)),
      color-stop(50%, rgba(93, 68, 165, 1)),
      color-stop(100%, rgba(218, 7, 162, 1))
    );
    background: -webkit-linear-gradient(
      left,
      rgba(53, 176, 230, 1) 0%,
      rgba(93, 68, 165, 1) 50%,
      rgba(218, 7, 162, 1) 100%
    );
    background: -o-linear-gradient(
      left,
      rgba(53, 176, 230, 1) 0%,
      rgba(93, 68, 165, 1) 50%,
      rgba(218, 7, 162, 1) 100%
    );
    background: -ms-linear-gradient(
      left,
      rgba(53, 176, 230, 1) 0%,
      rgba(93, 68, 165, 1) 50%,
      rgba(218, 7, 162, 1) 100%
    );
    background: linear-gradient(
      to right,
      rgba(53, 176, 230, 1) 0%,
      rgba(93, 68, 165, 1) 50%,
      rgba(218, 7, 162, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35b0e6', endColorstr='#da07a2', GradientType=1 );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }
}

.text-pink {
  color: #df05ff;
}
