input[type="text"] {
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background-color: $input-background;
  border: 1px solid rgba($color: $glowing-purple, $alpha: 0.25);
  padding: 0 25px;
  color: white;
  margin-bottom: 20px;
  transition: all 0.36s ease;

  &:focus {
    outline: none;
    border-color: $glowing-purple;
  }

  &::placeholder {
    color: $light-blue;
  }

  &.primary {
    background-color: $light-blue;
    &::placeholder {
      color: $dark-purple;
    }
  }
}
