$bold: "senbold", sans-serif;
$extrabold: "senextrabold", sans-serif;
$regular: "senregular", sans-serif;

$glowing-purple:  #ff00ff;
$glowing-button: #b590ba;
$dark-purple: #0f0230;
$border-grey: #e9ebf4;
$light-blue: #5d8aea;
$input-background: #191A5C;
$tab-button: #100637;
$glowing-blue: #BB7EC8;
$pink: #df05ff;
$blue: #0968b8;
$light-purple: #ff90ff;

$total: 300; // total particles
$orb-size: 200px;
$orb-size-mobile: 160px;
$particle-size: 2px;
$time: 14s; 
$base-hue: 180; // change for diff colors (180 is nice)